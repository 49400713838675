import clsx from 'clsx'
import { useCompanyActions } from 'features/company/api/useCompanyActions'
import { useInnCompany } from 'features/company/api/useCompanyInn'
import { useCompanyTypes } from 'features/company/api/useCompanyTypes'
import { useGetUser } from 'features/profile/api/useGetUser'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useAlertMessage } from 'shared/hooks'
import AuthService from 'shared/services/auth/auth.service'
import { ICompanyType } from 'shared/services/types/company.types'
import { MultiSelectCompanyTypes } from 'shared/ui/form/custom-select'
import { Input } from 'shared/ui/form/input'
import { ReactSelect } from 'shared/ui/form/react-select'
import { tokenParse } from 'shared/utils'
import './ProfileInfoCompany.scss'

const defaultCompanyData = {
	inn: '',
	name: '',
	type: '',
	full_name: '',
	english_name: '',
	director: '',
	ogrn: '',
	kpp: null,
	nds: undefined,
	index: '',
	juridical_address: '',
	actual_address: '',
	correspondence_address: '',
	bank_name: '',
	correspondent_bill: '',
	settlement_bill: '',
	constraints: undefined,
	license: undefined,

	city: '',
	address: '',
	bank_code: ''
}

interface IProps {
	companyInfo: any
}
const ProfileInfoCompany: FC<IProps> = ({ companyInfo }) => {
	const { is_company_owner, user_id } = tokenParse()
	const { userData } = useGetUser(user_id)
	const methods = useForm({ mode: 'all' })
	const {
		control,
		formState: { errors }
	} = methods

	const [isClick, setIsClick] = useState(false)
	const [companyData, setCompanyData] = useState(defaultCompanyData)
	const { addAlertMessage } = useAlertMessage()
	const { createCompany, updateCompany } = useCompanyActions()
	const { companyTypesData, error, isError, isPending } = useCompanyTypes()
	const { getInfoCompany } = useInnCompany({
		inn: companyData?.inn,
		country: methods.watch('country')?.value
	})
	const [companyTypesList, setCompanyTypesList] = useState<ICompanyType[]>()

	const [companyErrors, setCompanyErrors] = useState({
		inn: false,
		name: false,
		full_name: false,
		english_name: false,
		director: false,
		ogrn: false,
		kpp: false,
		index: false,
		nds: undefined,
		juridical_address: false,
		actual_address: false,
		correspondence_address: false,
		bank_name: false,
		correspondent_bill: false,
		settlement_bill: false,

		city: false,
		address: false,
		bank_code: false
	})

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target
		methods.setValue(name, value)
		setCompanyData(prevState => ({
			...prevState,
			[name]: value
		}))
	}

	const handleErrorChange = (
		event: React.MouseEvent<HTMLInputElement, MouseEvent>
	) => {
		//@ts-ignore
		const { name } = event.target
		//@ts-ignore
		if (companyErrors[name]) {
			setCompanyErrors(prevState => ({
				...prevState,
				[name]: false
			}))
			setCompanyData(prevState => ({
				...prevState,
				[name]: ''
			}))
		}
	}

	const handleTypeSelect = (selectedOptions: any) => {
		setCompanyData(prevState => ({
			...prevState,
			type: selectedOptions.map((i: any) => {
				return i.id
			})
		}))
	}

	const getCompanyTypes = async () => {
		if (isError && error && !isPending) {
			//@ts-ignore
			return addAlertMessage(error, 'error')
		}
		setCompanyTypesList(companyTypesData)
	}

	useEffect(() => {
		const { isPending, data, isError, error } = getInfoCompany
		if (!isClick) return
		if (!isPending && isError) {
			//@ts-ignore
			const errorMessage = getInfoCompany.error?.response?.data?.detail

			addAlertMessage(
				errorMessage || 'Произошла ошибка при подгрузке данных от налоговой',
				'error'
			)
		} else if (!isPending && data) {
			const updateDate = {
				...methods.getValues(),
				...data,
				address: data.juridical_address
			}

			methods.reset(updateDate)
			addAlertMessage(
				'Данные успешно получены, заполните оставшиеся поля и отправьте данные о своей компании',
				'success'
			)
		}
	}, [getInfoCompany.data, getInfoCompany.error, getInfoCompany.isPending])

	useEffect(() => {
		getCompanyTypes()
	}, [companyTypesData])

	const handlerErrorResponse = (error: any) => {
		if (error.response.status === 400) {
			const errorData = error.response.data
			setCompanyErrors(errorData)

			for (const key in errorData) {
				const valueError = Array.isArray(errorData[key])
					? String(errorData[key])
					: errorData[key]

				methods.setError(key, { message: valueError })
			}
		} else {
			if (error?.response?.data?.detail) {
				addAlertMessage(error?.response?.data?.detail, 'error')
			}
		}
	}

	const sendCompanyInfo = async () => {
		methods.trigger()

		if (Object.keys(errors).length) return null

		const newDate = { ...companyData, ...methods.getValues() }

		if (companyInfo) {
			try {
				// @ts-ignore
				delete newDate.correspond_languages
				// @ts-ignore
				delete newDate.speak_languages

				const dataCompanyUpdate = {
					...newDate,
					country_id: userData?.country.id,
					english_name: methods.getValues('english_name')
				}
				// @ts-ignore
				await updateCompany.mutateAsync(dataCompanyUpdate)
				addAlertMessage('Сохранено', 'success')
			} catch (error: any) {
				addAlertMessage(error, 'error')
				handlerErrorResponse(error)
			}
		} else {
			try {
				if (!userData?.country.id) {
					return addAlertMessage('Заполните поле "Страна проживания" ', 'error')
				}
				const newDate = { ...companyData, ...methods.getValues() }

				//@ts-ignore
				await createCompany.mutateAsync({
					...newDate,
					//@ts-ignore
					country_id: userData?.country.id
				})
				addAlertMessage('Сохранено', 'success')
				AuthService.refresh()
			} catch (error: any) {
				handlerErrorResponse(error)
			}
		}
	}

	useEffect(() => {
		if (companyInfo) {
			setCompanyData(companyInfo)
			methods.reset({ ...companyInfo })
		}
	}, [companyInfo])

	const disabledField = !is_company_owner && companyInfo

	return (
		<form
			className='profilecompany-form'
			onSubmit={e => {
				e.preventDefault()
				sendCompanyInfo()
			}}
		>
			{disabledField && (
				<p style={{ marginBottom: '2rem' }} className='profile-message'>
					Вы не владелец компании. Изменить данные нельзя.
				</p>
			)}
			<div className='select-block'>
				<MultiSelectCompanyTypes
					disabled={disabledField}
					onChange={handleTypeSelect}
					multiSelectOptions={companyTypesList || []}
					defaultRef={companyData.type}
				/>
			</div>
			<ReactSelect
				className='select-gender'
				control={control}
				name='country'
				label='Страна выдачи налогового номера'
				error={errors}
				options={[
					{
						value: 'RUS',
						label: 'ИНН - Россия'
					},
					{
						value: 'KAZ',
						label: 'БИН - Казахстан'
					},
					{
						value: 'BLR',
						label: 'УНП - Беларусь'
					},
					{
						value: 'KGZ',
						label: 'ИНН - Кыргызстан'
					},
					{
						value: 'UZB',
						label: 'TIN - Узбекистан'
					},
					{
						value: 'UKR',
						label: 'ЄДРПОУ - Украина'
					}
				]}
			/>
			<div>
				<div className='inputs-row'>
					<div className='input-block'>
						<input
							style={{
								color: companyErrors.inn ? 'red' : '',
								fontSize: companyErrors.inn ? '14px' : ''
							}}
							onClick={handleErrorChange}
							name='inn'
							value={companyData.inn}
							onChange={handleInputChange}
							disabled={disabledField}
							required
							className={clsx(companyData.inn && 'active')}
						/>
						<div className='input-placeholder'>
							Введите налоговый номер <span>*</span>
						</div>
					</div>
					{(!disabledField && methods.watch('country')?.value !== 'UKR') && (
						<div className='input-submit-block'>
							<button
								className='btn'
								type='button'
								disabled={disabledField || getInfoCompany.isFetching}
								onClick={() => {
									if (methods.watch('country')) {
										setIsClick(true)
										getInfoCompany.refetch()
									} else {
										methods.setError('country', {
											message: 'Необходимо выбрать страну'
										})
									}
								}}
							>
								Заполнить
							</button>

						</div>
					)}
				</div>
			</div>
			<div className='inputs-row'>
				<Input
					placeholder='Наименование'
					control={control}
					label='Наименование'
					name='name'
					disabled={disabledField}
					error={errors}
					required
					rules={{
						required: 'Обязательно поле для заполнения'
					}}
				/>
				<Input
					placeholder='Наименование на английском'
					control={control}
					label='Наименование на английском'
					name='english_name'
					disabled={disabledField}
					error={errors}
					rules={{
						pattern: {
							message: 'Название должно содержать латинские буквы',
							// eslint-disable-next-line no-useless-escape
							value: /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/
						}
					}}
				/>
			</div>
			<div className='inputs-row'>
				<Input
					placeholder='Город'
					control={control}
					label='Город'
					name='city'
					disabled={disabledField}
					error={errors}
				/>
				<Input
					placeholder='Адрес'
					control={control}
					label='Адрес'
					name='address'
					disabled={disabledField}
					error={errors}
				/>
			</div>
			<div className='inputs-row'>
				<Input
					placeholder='БИК / SWIFT'
					control={control}
					label='БИК / SWIFT'
					name='bank_code'
					disabled={disabledField}
					error={errors}
				/>
				<Input
					placeholder='Наименование банка'
					control={control}
					label='Наименование банка'
					name='bank_name'
					disabled={disabledField}
					error={errors}
				/>
			</div>

			<Input
				placeholder='Расчетный счет'
				control={control}
				label='Расчетный счет'
				name='settlement_bill'
				disabled={disabledField}
				error={errors}
			/>

			{!disabledField && (
				<div className='block-save block-term'>
					<div className='block-save-left'>
						<button className='btn' type='submit' disabled={disabledField}>
							Сохранить
						</button>
						<div className='terms-div'>
							<input
								checked={companyInfo}
								className='terms-button'
								type='checkbox'
								required
							/>
							<p>
								Нажав кнопку сохранить вы даете согласие на обработку{' '}
								<Link target='_blank' to='/privacy_policy'>
									персональных данных
								</Link>{' '}
								и подтверждаете, что вы совершеннолетний
							</p>
						</div>
					</div>
				</div>
			)}
		</form>
	)
}

export default ProfileInfoCompany
