import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import './ProjectPage.scss'
import { Link } from 'react-router-dom'
import {
	CommentIcon,
	SettingsSearchBarIcon,
	TrashIcon,
	SortIcon,
	SettingsPaletteIcon
} from '../../../assets/SearchBarIcons'
import { axiosInstance } from '../../../shared/api'
import fileDownload from 'js-file-download'
import { DistributorIcon, getSupplerIcon } from '../../../assets/SupplersIcons'
import { ChoosePartnerPopup } from '../../../features/profile/ui/popup-profile'
import ProductsAmount from '../../../features/projects/ui/product-amount'
import { TwitterPicker } from 'react-color'
import {
	useGetCountries,
	useGetCurrencies,
	useGetLanguages
} from 'features/utils/api'
import {
	useAlertMessage,
	useOutsideClick,
	useValueCurrency
} from 'shared/hooks'
import { distributerList } from 'widgets/products'
import { useProjectByIdActions } from 'features/projects/api/useProjectByIdActions'
import { useGetTariff } from 'features/tariff/api/useGetTariff'
import { Loader } from 'shared/ui/loader'
import { useGetProjectInfo } from 'features/projects/api/useGetProjects'
import { IProjectInfoResponse } from 'shared/services/types/project.types'
import { PromptText } from 'shared/ui/prompt-text'
import CollapsibleText from 'shared/ui/collapsible'
import { countryCodeName } from 'shared/constants'

const ProjectPage = () => {
	const [projectInfo, setProjectInfo] = useState<IProjectInfoResponse>()
	const param = useParams()
	const commentRef = useRef<HTMLInputElement>(null)
	const navigate = useNavigate()
	const location = useLocation()
	const { addAlertMessage } = useAlertMessage()
	const { addComment, updateProject, deleteProduct } = useProjectByIdActions(
		projectInfo?.id
	)

	const [projectsProductsList, setProjectsProductsList] = useState<any[]>()
	const [openComments, setOpenComments] = useState(false)
	const [openChoosePartner, setOpenChoosePartner] = useState(false)
	const [openPicker, setOpenPicker] = useState(false)
	const [color, setColor] = useState<any>()
	const { ref } = useOutsideClick(() => setOpenPicker(false))
	const { getCurrencyCounty, currency } = useValueCurrency()
	const [filter, setFilter] = useState<any>()
	//@ts-ignore
	const { data: dataProj, isLoading } = useGetProjectInfo(param['*'], filter)

	const { data: currencies } = useGetCurrencies()
	const { data: countries } = useGetCountries()

	const togglePicker = () => {
		setOpenPicker(!openPicker)
	}

	const toggleChoosePartner = () => {
		setOpenChoosePartner(!openChoosePartner)
	}

	const [openTableSettings, setOpenTableSettings] = useState(false)

	const defaultSettings = [
		'country_id',
		'article',
		'id',
		'name',
		'prices',
		'stock_available',
		'supplier'
	]

	const { tariffName, isPending } = useGetTariff()

	const [settingsParam, setSettingsParam] = useState(defaultSettings)
	const [newSettingsParam, setNewSettingsParam] = useState(defaultSettings)
	const [openSidebar, setOpenSidebar] = useState(false)
	const [quantityParam, setQuantityParam] = useState([''])
	const [hasWarehouse, setHasWarehouse] = useState('')
	const [openAvailableFilter, setOpenAvailableFilter] = useState(true)
	const [countryParam, setCountryParam] = useState<any>()
	const [openCountriesFilter, setOpenCountriesFilter] = useState(true)
	const [openManufacturerFilter, setOpenManufacturerFilter] = useState(true)

	const [distributerParam, setDistributerParam] = useState(
		distributerList.map(v => v.value.toString())
	)

	const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const idCountry = e.target.value
		if (idCountry === 'all') return setQuantityParam([])

		setQuantityParam(prev => {
			return prev.includes(idCountry)
				? prev.filter(id => id !== idCountry)
				: [...prev, idCountry]
		})
	}

	const onWareHouseChange = () => {
		setHasWarehouse(prev => (prev === '1' ? '0' : '1'))
	}

	const onCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const idCountry = e.target.value
		setCountryParam((prev: any) => {
			return prev.includes(idCountry)
				? prev.filter((id: string) => id !== idCountry)
				: [...prev, idCountry]
		})
	}

	const toggleSidebar = () => {
		setOpenSidebar(!openSidebar)
	}

	const onSettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		if (newSettingsParam.includes(value)) {
			setNewSettingsParam(newSettingsParam.filter(param => param !== value))
		} else {
			setNewSettingsParam([...newSettingsParam, value])
		}
	}

	const onDistributerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const idCountry = e.target.value
		setDistributerParam(prev => {
			return prev.includes(idCountry)
				? prev.filter(id => id !== idCountry)
				: [...prev, idCountry]
		})
	}

	const toggleTableSettings = () => {
		setOpenTableSettings(!openTableSettings)
	}

	const getQueryParams = (): any => {
		const { search } = location
		const clearSearch = search.replace('?', '')
		const helperArray = clearSearch.split('&')
		const data = helperArray.reduce((acc, item) => {
			const [name, value] = item.split('=')
			return {
				...acc,
				[name]: value?.includes(',') ? value.split(',') : value
			}
		}, {})
		return data
	}

	useEffect(() => {
		const countriesID =
			getQueryParams()?.country || countries?.map(v => v.id.toString())
		setCountryParam(countriesID)
	}, [countries])

	const goBack = () => {
		navigate(-1)
	}

	const onDeleteProduct = async (product_id: number) => {
		try {
			await deleteProduct.mutateAsync(product_id)

			getProjectsProducts()
		} catch (error) {
			console.log(error)
		}
	}

	const getComments = async () => {
		try {
			await addComment.mutateAsync({
				text: commentRef!.current!.value
			})

			await getProjectsProducts()

			addAlertMessage('Комментарий добавлен', 'success')
			commentRef!.current!.value = ''
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	const onUpdateProject = async (e: any) => {
		try {
			await updateProject.mutateAsync({ color: e.hex })
			setColor(e.hex)
			addAlertMessage('Цвет изменен', 'success')
			setOpenPicker(false)
		} catch (error: any) {
			addAlertMessage(error, 'error')
		}
	}

	const getExcelFile = async () => {
		try {
			const id = location.pathname.split('/')
			const project_id = id.pop()
			const data = await axiosInstance.get(`/project/${project_id}/`)
			await axiosInstance
				.post(
					'/project/generate/excel/',
					{
						project_name: projectInfo?.name,
						products: [
							...data.data.products.map((i: any) => {
								return {
									count: i.count,
									product_id: i.id
								}
							})
						]
					},
					{ responseType: 'blob' }
				)
				.then(response => {
					const regex = /[a-zA-Zа-яА-Я0-9]/

					const fileName = regex.test(projectInfo!.name)
						? projectInfo!.name
						: `Проект „${projectInfo!.name}“`
					fileDownload(response.data, `${fileName}.xlsx`)
				})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (!countryParam || openSidebar) return
		const filterData = {
			currency: 'RUB',
			fields: [...newSettingsParam]?.join(','),
			quantity: quantityParam?.join(','),
			country: countryParam?.join(','),
			has_warehouse: hasWarehouse
		}
		setFilter(filterData)
	}, [newSettingsParam, quantityParam, countryParam, hasWarehouse, openSidebar])

	const getProjectsProducts = async () => {
		if (!dataProj) return

		try {
			//@ts-ignore
			const childrenProducts = dataProj?.child_projects?.reduce((acc, item) => {
				return [...acc, ...item.products]
			}, [])

			//@ts-ignore
			setProjectsProductsList([...dataProj.products, ...childrenProducts])
			setProjectInfo(dataProj)
			if (dataProj.color) setColor(dataProj.color)
			setSettingsParam(newSettingsParam)
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getProjectsProducts()
	}, [dataProj])

	return (
		<>
			<div className='projectpage'>
				<div className='container'>
					<div className='projectpage-header'>
						<div className='projectpage-header-title'>
							<div
								style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
							>
								<div
									className='projectpage-search-color'
									style={{ background: color || 'red' }}
								/>
								<h1>{projectInfo ? projectInfo.name : '⠀'}</h1>
							</div>
							<div className='projectpage-header-title-buttons'>
								<button className='excel-button' onClick={getExcelFile}>
									Скачать в EXL
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										viewBox='0 0 16 16'
										fill='none'
									>
										<g clip-path='url(#clip0_506_15753)'>
											<path
												d='M8 5.33301L8 10.6663M8 10.6663L10 8.66634M8 10.6663L6 8.66634'
												stroke='#333333'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
											<path
												d='M4.66927 2.22587C5.64985 1.65863 6.78831 1.33398 8.0026 1.33398C11.6845 1.33398 14.6693 4.31875 14.6693 8.00065C14.6693 11.6826 11.6845 14.6673 8.0026 14.6673C4.32071 14.6673 1.33594 11.6826 1.33594 8.00065C1.33594 6.78636 1.66059 5.6479 2.22782 4.66732'
												stroke='#333333'
												strokeLinecap='round'
											/>
										</g>
										<defs>
											<clipPath id='clip0_506_15753'>
												<rect width='16' height='16' fill='white' />
											</clipPath>
										</defs>
									</svg>
								</button>
								<button className='back-btn' onClick={goBack}>
									Назад
								</button>
							</div>
						</div>
					</div>
					<div className='projectpage-search-panel'>
						<div className='projectpage-search-panel-settings'>
							<button onClick={toggleTableSettings}>
								<SettingsSearchBarIcon />
							</button>
							<button onClick={toggleSidebar}>
								<SortIcon />
							</button>
						</div>
						<div className='projectpage-search-panel-palette' ref={ref}>
							<button
								className={openPicker ? 'active' : ''}
								onClick={togglePicker}
							>
								<SettingsPaletteIcon />
							</button>
							<TwitterPicker
								onChangeComplete={onUpdateProject}
								color={color}
								className={openPicker ? 'open' : ''}
							/>
						</div>
						{!!openComments && (
							<div
								onClick={() => setOpenComments(false)}
								className={'profile-overlay'}
							></div>
						)}
						<div
							onClick={() => setOpenComments(true)}
							className={`projectpage-search-panel-comment ${
								openComments ? 'active' : ''
							}`}
						>
							<form
								onSubmit={e => {
									e.preventDefault()
									if (!commentRef?.current?.value) return
									getComments()
								}}
							>
								<input
									ref={commentRef}
									type='text'
									placeholder='Введите свой комментарий...'
								/>
								<button>
									<CommentIcon color={openComments ? '#8A7DE7' : 'white'} />
								</button>
							</form>
							{!true && <Loader isFixed />}
							<div className={`comments-field ${openComments ? 'active' : ''}`}>
								{projectInfo ? (
									projectInfo.comments.map(i => {
										return (
											<div className='comments-field-div'>
												<div className='comments-field-user'>
													<p>{i.user.last_name + ' ' + i.user.first_name}</p>
												</div>
												<p className='comments-field-text'>{i.text}</p>
											</div>
										)
									})
								) : (
									<></>
								)}
								<br />
							</div>
						</div>
					</div>
					{isLoading && <Loader isFixed />}
					{projectsProductsList?.length && countries && currencies ? (
						<div className='projectpage-table'>
							<table>
								<thead>
									<tr>
										<th className='country-col'>Страна</th>
										<th className='article-col'>Артикул</th>
										{settingsParam.includes('name') ? (
											<th className='name-col'>Название</th>
										) : (
											<></>
										)}
										{settingsParam.includes('prices') ? (
											<th className='currency-col' style={{ width: '120px' }}>
												Цена ({currency})
											</th>
										) : (
											<></>
										)}
										{settingsParam.includes('stock_available') ? (
											<th className='available-col'>Доступно</th>
										) : (
											<></>
										)}
										{settingsParam.includes('stock_quantity') ? (
											<th className='instock-col'>На складе</th>
										) : (
											<></>
										)}
										{settingsParam.includes('transit_available') ? (
											<th className='transit-col'>В транзите</th>
										) : (
											<></>
										)}
										{settingsParam.includes('transit_quantity') ? (
											<th className='transit-col'>Доступно в транзите</th>
										) : (
											<></>
										)}
										{settingsParam.includes('supplier') ? (
											<th className='manufacturer-col'>Поставщик</th>
										) : (
											<></>
										)}
										<th className='product-ammount-col'>Ввести количество</th>
										<th className='trash-col'></th>
									</tr>
								</thead>
								<tbody>
									{projectsProductsList?.map(i => {
										return (
											<tr key={i.id}>
												<td className='country-col' aria-label='Страна'>
													<div>
														<p>
															{/* @ts-ignore */}
															{countryCodeName[i.country_id]}
														</p>

														<img
															className={'country-col-flag'}
															src={
																countries?.find(l => l.id === i.country_id)
																	?.flag
															}
															alt='страна'
															height='32'
															width='36'
														/>
													</div>
												</td>
												<td className='article-col' aria-label='Артикул'>
													<p>{i.article}</p>
												</td>
												{settingsParam.includes('name') && (
													<td className='name-col' aria-label='Название'>
														<CollapsibleText text={i.name} />
													</td>
												)}
												{settingsParam.includes('prices') ? (
													<td className='currency-col' aria-label='Цена'>
														<p> {getCurrencyCounty(i.prices[2].price)}</p>
													</td>
												) : (
													<></>
												)}
												{settingsParam.includes('stock_available') ? (
													<td className='available-col' aria-label='Доступно'>
														<p>{i.stock_available}</p>
													</td>
												) : (
													<></>
												)}
												{settingsParam.includes('stock_quantity') ? (
													<td className='instock-col' aria-label='На складе'>
														<p>{i.stock_quantity}</p>
													</td>
												) : (
													<></>
												)}
												{settingsParam.includes('transit_available') ? (
													<td className='transit-col' aria-label='В транзите'>
														<p>{i.transit_available}</p>
													</td>
												) : (
													<></>
												)}
												{settingsParam.includes('transit_quantity') ? (
													<td
														className='transit-col'
														aria-label='111'
													>
														<p>
															{i.transit_quantity ? i.transit_quantity : '*'}
														</p>
													</td>
												) : (
													<></>
												)}
												{settingsParam.includes('supplier') ? (
													<td
														className='manufacturer-col'
														aria-label='Поставщик'
													>
														<div>
															<div
																style={{
																	position: 'relative',
																	width: '15px',
																	height: '15px',
																	flexShrink: 0
																}}
															>
																<PromptText
																	text={`${
																		distributerList.find(
																			item =>
																				item.value ===
																				i?.supplier?.type?.toLowerCase()
																		)?.help
																	}`}
																>
																	{getSupplerIcon(
																		i?.supplier?.type?.toLowerCase()
																	)}
																</PromptText>
															</div>

															<Link
																className='distributor-col'
																to={`/distributor-page/${i.supplier.slug}`}
															>
																{i.supplier.name}
															</Link>
														</div>
													</td>
												) : (
													<></>
												)}
												<td
													className='product-ammount-col'
													aria-label='Ввести количество'
												>
													<ProductsAmount
														data={i}
														id={Number(projectInfo?.id)}
													/>
												</td>
												<td className='trash-col' aria-label=''>
													<button onClick={() => onDeleteProduct(i.id)}>
														<TrashIcon />
													</button>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					) : (
						<div className={'projects-empty'}>
							<>В данном проекте у вас ещё нет товаров</>
						</div>
					)}

					{!!projectsProductsList &&
						!!countries &&
						!!currencies &&
						!!projectsProductsList?.length && (
							<div className='products-mob project-mob'>
								{projectsProductsList.map(i => {
									return (
										<div className='products-mob-item' key={i.id}>
											{settingsParam.includes('country_id') ? (
												<div className='products-mob-item__row'>
													<div className='products-mob-item__col-name'>
														Страна <br />
														Артикул
													</div>
													<div className='products-mob-item__col-value'>
														<img
															className='flag'
															src={
																countries?.find(l => l.id == i.country_id)?.flag
															}
															height='24'
															width='34'
														/>
														<p className='code'>
															{/* @ts-ignore */}
															{countryCodeName[i.country_id]}
														</p>
														<p className='article'>{i.article}</p>
													</div>
												</div>
											) : (
												<></>
											)}
											<div className='products-mob-item__row'>
												<div className='products-mob-item__col-name'>
													Название
												</div>
												<div className='products-mob-item__col-value'>
													<p>{i.name}</p>
												</div>
											</div>
											{settingsParam.includes('stock_available') ? (
												<div className='products-mob-item__row'>
													<div className='products-mob-item__col-name'>
														В наличии
													</div>
													<div
														className='products-mob-item__col-value'
														aria-label='Доступно'
													>
														<p>{i.stock_available}</p>
													</div>
												</div>
											) : (
												<></>
											)}

											{settingsParam.includes('transit_available') ? (
												<div className='products-mob-item__row'>
													<div className='products-mob-item__col-name'>
														В транзите
													</div>
													<div
														className='products-mob-item__col-value'
														aria-label='В транзите'
													>
														<p>{i.transit_available}</p>
													</div>
												</div>
											) : (
												<></>
											)}
											{settingsParam.includes('supplier') ? (
												<div className='products-mob-item__row'>
													<div className='products-mob-item__col-name'>
														Поставщик
													</div>
													<div
														className='products-mob-item__col-value'
														aria-label='Поставщик'
													>
														<div className='distributor'>
															<div
																style={{
																	position: 'relative',
																	width: '15px',
																	height: '15px'
																}}
															>
																<PromptText
																	text={`${
																		distributerList.find(
																			item =>
																				item.value ===
																				i?.supplier?.type?.toLowerCase()
																		)?.help
																	}`}
																>
																	{getSupplerIcon(
																		i?.supplier?.type?.toLowerCase()
																	)}
																</PromptText>
															</div>
															<Link
																className='distributor-col'
																to={`/distributor-page/${i.supplier.slug}`}
															>
																{i.supplier.name}
															</Link>
														</div>
													</div>
												</div>
											) : (
												<></>
											)}
											{settingsParam.includes('prices') && (
												<div className='products-mob-item__row'>
													<div className='products-mob-item__col-name'>
														Цена {currency}
													</div>
													<div
														className='products-mob-item__col-value'
														aria-label='В транзите'
													>
														<p> {getCurrencyCounty(i.prices[2].price)}</p>
													</div>
												</div>
											)}

											<div className='products-mob-item__row'>
												<div className='products-mob-item__col-name'>
													Количество
												</div>
												<div
													className='products-mob-item__col-value'
													aria-label='Количество'
												>
													<ProductsAmount
														data={i}
														id={Number(projectInfo?.id)}
													/>

													<button
														className='trash'
														onClick={() => onDeleteProduct(i.id)}
													>
														<TrashIcon />
													</button>
												</div>
											</div>
										</div>
									)
								})}
							</div>
						)}

					<div className='projectpage-table-buttons'>
						<button onClick={getExcelFile}>
							Скачать в EXL
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='21'
								height='20'
								viewBox='0 0 21 20'
								fill='none'
							>
								<path
									d='M10.5 6.66699L10.5 13.3337M10.5 13.3337L13 10.8337M10.5 13.3337L8 10.8337'
									stroke='#333333'
									strokeWidth='1.5'
									strokeLinecap='round'
									strokeLinejoin='round'
								/>
								<path
									d='M6.33073 2.78087C7.55645 2.07183 8.97953 1.66602 10.4974 1.66602C15.0998 1.66602 18.8307 5.39698 18.8307 9.99935C18.8307 14.6017 15.0998 18.3327 10.4974 18.3327C5.89502 18.3327 2.16406 14.6017 2.16406 9.99935C2.16406 8.48149 2.56987 7.05841 3.27891 5.83268'
									stroke='#333333'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
						{/* //TODO временно скрыт */}
						{/* <button onClick={toggleChoosePartner}>Подобрать партнера</button> */}
						<ChoosePartnerPopup
							isOpen={openChoosePartner}
							setIsOpen={setOpenChoosePartner}
							data={projectInfo}
							dataProject={projectsProductsList}
						/>
					</div>

					<div
						onClick={toggleSidebar}
						className={`overlay ${openSidebar ? 'open' : ''}`}
					></div>
					<div className={`sidebar ${openSidebar ? 'open' : ''}`}>
						<nav className={`sidebar-nav`}>
							<button className='sidebar-close' onClick={toggleSidebar}>
								<svg
									width='37'
									height='37'
									viewBox='0 0 37 37'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M22.875 14.125L14.125 22.875M14.125 14.125L22.8749 22.875'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
									<path
										d='M36 18.5C36 26.7496 36 30.8744 33.4372 33.4372C30.8744 36 26.7496 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4372C1 30.8744 1 26.7496 1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7496 1 30.8744 1 33.4372 3.56282C35.1412 5.26686 35.7122 7.66147 35.9036 11.5'
										stroke='#8A7DE7'
										strokeWidth='1.5'
										strokeLinecap='round'
									/>
								</svg>
							</button>
							<div>
								<p
									className={`sidebar-nav-filter-header ${
										openAvailableFilter ? 'opened' : 'closed'
									}`}
									onClick={() => setOpenAvailableFilter(!openAvailableFilter)}
								>
									<i>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='6'
											viewBox='0 0 12 6'
											fill='none'
										>
											<path
												d='M0.750609 0.75L6.00061 5.25L11.2506 0.75'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</i>
									Фильтр по наличию
								</p>
								{openAvailableFilter && (
									<>
										<label className='sidebar-nav-options'>
											<input
												type='checkbox'
												name='quantity'
												value='0'
												checked={quantityParam?.includes('0')}
												onChange={onQuantityChange}
											/>
											<span>Только в наличии</span>
										</label>
										<label className='sidebar-nav-options'>
											<input
												type='checkbox'
												name='quantity'
												value='1'
												checked={quantityParam?.includes('1')}
												onChange={onQuantityChange}
											/>
											<span>В наличии или в транзите</span>
										</label>
										<label className='sidebar-nav-options'>
											<input
												type='checkbox'
												name='warehouse'
												value={'0'}
												checked={hasWarehouse === '0'}
												onChange={onWareHouseChange}
											/>
											<span>Без склада</span>
										</label>
									</>
								)}
							</div>
							<div>
								<p
									className={`sidebar-nav-filter-header ${
										openCountriesFilter ? 'opened' : 'closed'
									}`}
									onClick={() => setOpenCountriesFilter(!openCountriesFilter)}
								>
									<i>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='6'
											viewBox='0 0 12 6'
											fill='none'
										>
											<path
												d='M0.750609 0.75L6.00061 5.25L11.2506 0.75'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</i>
									Фильтр по странам
								</p>
								{countries && openCountriesFilter ? (
									countries?.map(i => {
										if ([2, 4].includes(i.id)) return null
										return (
											<label className='sidebar-nav-options' key={i.id}>
												<input
													key={i.id}
													type='checkbox'
													name='country'
													value={i.id}
													checked={countryParam?.includes(i.id.toString())}
													onChange={onCountryChange}
												/>
												<span>{i.name}</span>
											</label>
										)
									})
								) : (
									<></>
								)}
							</div>
							<div>
								<p
									className={`sidebar-nav-filter-header ${
										openManufacturerFilter ? 'opened' : 'closed'
									}`}
									onClick={() =>
										setOpenManufacturerFilter(!openManufacturerFilter)
									}
								>
									<i>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='12'
											height='6'
											viewBox='0 0 12 6'
											fill='none'
										>
											<path
												d='M0.750609 0.75L6.00061 5.25L11.2506 0.75'
												stroke='#333333'
												strokeWidth='1.5'
												strokeLinecap='round'
												strokeLinejoin='round'
											/>
										</svg>
									</i>
									Выбор поставщика
								</p>
								{openManufacturerFilter && (
									<>
										{distributerList.map(item => (
											<label className='sidebar-nav-options' key={item.value}>
												<input
													type='checkbox'
													name='supplier'
													value={item.value}
													onChange={onDistributerChange}
													checked={distributerParam?.includes(
														item.value.toString()
													)}
												/>
												<span>{item.name}</span>
											</label>
										))}
									</>
								)}
							</div>
							<div>
								<button
									className='filter-apply'
									onClick={() => {
										getProjectsProducts()
										setOpenSidebar(false)
									}}
								>
									Применить
								</button>
							</div>
						</nav>
					</div>
				</div>

				<div
					onClick={toggleTableSettings}
					className={`overlay ${openTableSettings ? 'open' : ''}`}
				></div>
				<div className={`sidebar ${openTableSettings ? 'open' : ''}`}>
					<nav className={`sidebar-nav`}>
						<button className='sidebar-close' onClick={toggleTableSettings}>
							<svg
								width='37'
								height='37'
								viewBox='0 0 37 37'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M22.875 14.125L14.125 22.875M14.125 14.125L22.8749 22.875'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
								<path
									d='M36 18.5C36 26.7496 36 30.8744 33.4372 33.4372C30.8744 36 26.7496 36 18.5 36C10.2504 36 6.12563 36 3.56282 33.4372C1 30.8744 1 26.7496 1 18.5C1 10.2504 1 6.12563 3.56282 3.56282C6.12563 1 10.2504 1 18.5 1C26.7496 1 30.8744 1 33.4372 3.56282C35.1412 5.26686 35.7122 7.66147 35.9036 11.5'
									stroke='#8A7DE7'
									strokeWidth='1.5'
									strokeLinecap='round'
								/>
							</svg>
						</button>
						<div>
							<p className={`sidebar-nav-filter-header`}>Настройка таблицы</p>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='country_id'
									checked
									onChange={onSettingsChange}
									disabled
								/>
								<span>Страна</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='article'
									checked
									onChange={onSettingsChange}
									disabled
								/>
								<span>Артикул</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='id'
									checked
									onChange={onSettingsChange}
									disabled
								/>
								<span>ID товара</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='name'
									checked={newSettingsParam.includes('name')}
									onChange={onSettingsChange}
								/>
								<span>Название</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='prices'
									checked={newSettingsParam.includes('prices')}
									onChange={onSettingsChange}
								/>
								<span>Цены</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='stock_available'
									checked={newSettingsParam.includes('stock_available')}
									onChange={onSettingsChange}
								/>
								<span>Доступно</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='stock_quantity'
									checked={newSettingsParam.includes('stock_quantity')}
									onChange={onSettingsChange}
								/>
								<span>На складе</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='transit_available'
									checked={newSettingsParam.includes('transit_available')}
									onChange={onSettingsChange}
								/>
								<span>Доступно в транзите</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='transit_quantity'
									checked={newSettingsParam.includes('transit_quantity')}
									onChange={onSettingsChange}
								/>
								<span>В транзите</span>
							</div>
							<div className='sidebar-nav-options'>
								<input
									type='checkbox'
									name='settings'
									value='supplier'
									checked={newSettingsParam.includes('supplier')}
									onChange={onSettingsChange}
								/>
								<span>Поставщик</span>
							</div>
						</div>
						<div>
							<button
								className='sidebar-confirm-button'
								onClick={() => {
									getProjectsProducts()
									setOpenTableSettings(false)
								}}
							>
								Применить
							</button>
						</div>
					</nav>
				</div>
			</div>
			{!tariffName && !isPending && (
				<div className='profile-overlay fixed'>
					<div className='profile-overlay-content'>
						<p>
							Сейчас у вас нет подписки, для полного функционала, пожалуйста,
							приобретите подписку{' '}
							<Link to='/account/tariffs'>“Тарифы и оплата”</Link>
						</p>
					</div>
					x
				</div>
			)}
		</>
	)
}

export default ProjectPage
