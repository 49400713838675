import clsx from 'clsx'
import { FC, useState } from 'react'
import ClampLines from 'react-clamp-lines'
import Truncate from 'react-truncate'

interface IProps {
	text: string
	lines?: number
}
const CollapsibleText: FC<IProps> = ({ text = '' }) => {
	const [isCollapsed, setIsCollapsed] = useState(true)

	const toggleCollapse = () => {
		setIsCollapsed(!isCollapsed)
	}

	return (
		<div className={clsx('collapsible-text', true && 'open')}>
			<Truncate
				lines={!isCollapsed ? false : 2}
				ellipsis={
					<span>
						...{' '}
						<span className={'collapse-button'} onClick={toggleCollapse}>
							Раскрыть
						</span>
					</span>
				}
			>
				{text}
			</Truncate>

			{!isCollapsed && (
				<button className={'collapse-button'} onClick={toggleCollapse}>
					Скрыть
				</button>
			)}
		</div>
	)
}

export default CollapsibleText

export const TextToggle: FC<IProps> = ({ text = '', lines = 2 }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const toggleText = () => {
		setIsExpanded(!isExpanded)
	}

	return (
		<div className={clsx('collapsible-text', true && 'open')}>
			<ClampLines
				text={text}
				id='custom'
				lines={lines}
				moreText='Раскрыть'
				lessText='Скрыть'
				className='custom-class'
				ellipsis='...'
				innerElement='span'
			/>
		</div>
	)
}
