import { axiosInstance } from 'shared/api'
import { getCompanyUrl } from 'shared/api/config'
import { IDataRegister } from 'shared/services/types/auth.types'
import {
	ICompanyData,
	ICompanyDataUpdate,
	ICompanyRequest,
	ICompanyResponse,
	ICompanyType,
	IIncreaseCompany,
	IInviteCompany
} from './types/company.types'
import { IUserUpdate } from './types/user.types'

const CompanyService = {
	async getCompany(id?: number) {
		const response = await axiosInstance.get<ICompanyResponse>(
			getCompanyUrl(`/`),
			{ params: id }
		)
		return response
	},

	async createCompany(data: ICompanyData) {
		const response = await axiosInstance.post<ICompanyResponse>(
			getCompanyUrl(`/`),
			data
		)
		return response
	},

	async updateCompany(data: ICompanyData) {
		const response = await axiosInstance.put<ICompanyResponse>(
			getCompanyUrl(`/`),
			data
		)
		return response
	},

	async updatePartCompany(data: ICompanyDataUpdate) {
		const response = await axiosInstance.patch<ICompanyResponse>(
			getCompanyUrl(`/`),
			data
		)
		return response
	},

	async getInfoByInn(data: ICompanyRequest) {
		const response = await axiosInstance.get<ICompanyResponse>(
			getCompanyUrl(`/get_info/${data.inn}/`),
			{
				params: { country: data.country }
			}
		)
		return response
	},

	async getIncreaseCompany(data: IIncreaseCompany) {
		const response = await axiosInstance.post<ICompanyResponse>(
			getCompanyUrl(`/increase_max/request/`),
			data
		)
		return response
	},

	async sendInviteToCompany(data: IInviteCompany) {
		const response = await axiosInstance.post<ICompanyResponse>(
			getCompanyUrl(`/invite/`),
			data
		)
		return response
	},
	async confirmInviteToCompany(
		data: IDataRegister,
		cidb64: string,
		token: string
	) {
		const response = await axiosInstance.post<ICompanyResponse>(
			getCompanyUrl(`/company/invite/confirm/${cidb64}/${token}/`),
			data
		)
		return response
	},

	async deleteCompanyUser(id: number) {
		const response = await axiosInstance.delete<string>(
			getCompanyUrl(`/remove/${id}/`)
		)
		return response
	},
	///
	async reportCompany(inn: string) {
		const response = await axiosInstance.get<string>(
			getCompanyUrl(`/report/`),
			{
				params: inn
			}
		)
		return response
	},
	async getTypesCompany() {
		const response = await axiosInstance.get<ICompanyType[]>(
			getCompanyUrl(`/types/`)
		)
		return response
	},

	async getUsersCompany() {
		const response = await axiosInstance.get<IUserUpdate[]>(
			getCompanyUrl(`/users/`)
		)
		return response
	}
}

export default CompanyService
