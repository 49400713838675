import { useGetCountries, useGetExchange } from 'features/utils/api'
import { useContext } from 'react'
import CommonContext from 'services/AuthContext'

function roundIfNotInteger(
	value: number,
	fixedNum: number,
	unrounded?: boolean
) {
	const precision = Math.pow(10, fixedNum)

	if (!Number.isInteger(value) && !unrounded) {
		// Форматируем с учётом дробной части
		return new Intl.NumberFormat('ru-RU').format(
			Math.round(value * precision) / precision
		)
	}

	const resultValue = new Intl.NumberFormat('ru-RU').format(value)

	// Возвращаем число как есть, если оно целое
	return resultValue.replace(',', '.')
}

export function useValueCurrency(
	hasCountryCurrency?: boolean,
	fixedNum: number = 2
) {
	const { data: exchangeRates } = useGetExchange()
	const { data: countries } = useGetCountries()
	const { currentCurrency } = useContext(CommonContext)
	const { label = 'RUB' } = currentCurrency

	// Универсальная функция для преобразования строк в числа
	const parseValue = (value: number | string) => {
		const parsedValue = Number(
			value.toString().replaceAll(',', '.').replaceAll(' ', '')
		)

		return isNaN(parsedValue) ? value : parsedValue
	}

	const getCurrencyCounty = (
		value?: number | string,
		unrounded?: boolean,
		isUSD?: boolean,
		isRUB?: boolean
	) => {
		if (!value) return undefined
		if (!exchangeRates || !label || label === 'RUB') {
			const numValue = parseValue(value)
			if (typeof numValue === 'string') return value

			const resultValue = roundIfNotInteger(numValue, fixedNum, unrounded)

			return hasCountryCurrency ? `${resultValue} ${label}` : resultValue
		}

		const currencyToRub = exchangeRates[label] || 1
		const usdToRub = exchangeRates[isRUB ? 'RUB' : 'USD'] || 1

		const numValue = parseValue(value)
		if (typeof numValue === 'string') return value

		if (isUSD || (label === 'USD' && !isRUB)) {
			return roundIfNotInteger(numValue, fixedNum, unrounded)
		}

		const priceInRub = numValue * usdToRub
		// Переводим цену в рубли, а затем в KZT
		const priceInKzt = priceInRub / currencyToRub

		const resultValue = roundIfNotInteger(priceInKzt, fixedNum)

		return hasCountryCurrency ? `${resultValue} ${label}` : resultValue
	}

	const getCountCurrency = (currency: 'USD' | 'EUR') => {
		if (!exchangeRates) return undefined

		if (!label || label === 'RUB') {
			return exchangeRates[currency].toFixed(2)
		}

		const rate = exchangeRates[currency] / (exchangeRates[label] || 1)
		return (Math.floor(rate * 100) / 100).toFixed(2)
	}

	const country =
		countries?.find(item => item.id === currentCurrency?.value)?.name ||
		'Россия'

	return {
		getCurrencyCounty,
		getCountCurrency,
		roundIfNotInteger,
		currency: label,
		country,
		currenciesExchange: exchangeRates
	}
}
