import { useQuery } from '@tanstack/react-query'
import CompanyService from 'shared/services/company.service'
import { ICompanyRequest } from 'shared/services/types/company.types'

export const useInnCompany = (data: ICompanyRequest) => {
	const getInfoCompany = useQuery({
		queryFn: () => CompanyService.getInfoByInn(data),
		queryKey: ['get info company inn'],
		enabled: false,
		select: ({ data }) => data,
		retry: 0
	})

	const getReportCompany = useQuery({
		queryFn: () => CompanyService.reportCompany(data.inn),
		queryKey: ['get info company report'],
		select: ({ data }) => data,
		enabled: !!data.inn
	})

	return { getInfoCompany, getReportCompany }
}
